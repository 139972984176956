import React from 'react';
import {Link} from 'gatsby'
export default ({image,content,name,cta})=>(
    <>
    <h2 className=" animated in-view" data-animatequeue="fadeIn">{name}</h2>
    <div className="row product-row middle" key={`product_${name}`} >
        <div className="col product-image animated in-view" data-animatequeue="slideInLeft">
            <img src={image} alt={name}/>
        </div>
        <div className="col column product-content animated in-view"  data-animatequeue="slideInRight">
            {content()}
            <div className="call-to-action">
                <Link to={cta.target} className="btn primary">{cta.text}</Link>
            </div>
        </div>
    </div>
    </>
)