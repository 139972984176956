import React from 'react'
import Layout from '../components/Layout'
import {Location} from '@reach/router'
import Product from '../components/Product';
import productImg1 from '../images/Schlage_Deadbolt_FE599.jpg'
import productImg2 from '../images/V521IR.jpg'
import productImg2a from '../images/ADC_webMobile_family.jpg'
import productImg3 from '../images/ADC_smart_thermostat.jpg'
import productImg4 from '../images/simonxti5-product_image_1400x1050.jpg'
import productImg4a from '../images/skybell-and-app.jpg'
import Content from '../components/Content'
const products = [
    {
        name:'Home Access',
        image:productImg1,
        content:()=>(
            <>
               <p>Easily manage access to your home or office with smart-locks and control panels</p>
               <p>Gain access to your home with a pin number instead of a key.</p>
            </>
        ),
        cta:{
            text:'Discover Home Access Solutions',
            target:'/services/access-control'
        }
    },
    {
        name:'Video Monitoring',
        image:productImg2,
        content:()=>(
            <>
              <p>Monitor your home from anywhere in the world using your internet connected smartphone, tablet or desktop.</p>
             <img src={productImg2a}/>
            </>
        ),
        cta: {
            text:'Discover Home Monitoring Solutions',
            target:'/services/home-monitoring'
        }
    },
    {
        name:'Energy Management',
        content:()=>(
            <>
            <p>Save time and money with smart-home thermostats and monitoring devices.</p>
            <p>Forget to set your thermostat before you leave the house? These smart devices will adjust temperature based on your activity and preferences.</p>
            </>
        ),
        image:productImg3,
        cta:{
            text:'Discover Energy Mangement Solutions',
            target:"/services/access-control"
        }
    },
    {
        name:'Security Systems',
        content:()=>(
            <>
                <p>We have partnered with best-in-class security panel providers to seamlessly integrate our Interactive Security services so you have the option to customize your system to best meet your needs.</p>
                <p>Our systems support a wide variety of sensors, as compatible with security panels. Some of the most common types of Sensors include:</p>
                <ul>
                    <li>Door & Window Contacts</li>
                    <li>Glass-Break Detectors</li>
                    <li>Infrared Motion Detectors</li>
                    <li>Shock/Vibration Sensors</li>
                    <li>24/7 Fire/Heat monitoring</li>
                    <li>Medical Alert Necklaces</li>
                    <li>Water/Flood/Freeze Monitors</li>
                </ul>
                <img src={productImg4a}/>
            </>
        ),
        image:productImg4,
        cta:{
            text:'Discover Security Sensor Solutions',
            target:'/services/home-monitoring'
        }
    }

]
export default () => (
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                    <Content>
                        {
                            products.map((product,i)=><Product key={i} {...product} />)
                        
                        }
                    </Content>
                </Layout>
            )
        }
    </Location>
    )